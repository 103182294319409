.row.landing-center {
	display: flex;
	min-height: 100vh;
	align-items: center;

	.landing-wrap {
		max-width: 530px;
		display: flex;
		margin: 0 auto;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: $landing-text;

		.landing-text-hold {
			text-align: center;

			.new-customer {
				font-size: 20px;
				font-family: 'Open Sans', sans-serif;
			}

			.risk-free {
				font-size: 50px;
				font-family: 'Open Sans', sans-serif;
				margin-bottom: 0;
			}

			.eth-number {
				font-size: 80px;
				font-weight: bold;
				font-family: 'Open Sans', sans-serif;
			}
		}

		.terms-help-wrap {
			display: flex;
			width: 70%;
			justify-content: space-evenly;

			a {
				&:hover {
					color: #0056b3;
				}
			}
		}

		> a {
			align-self: center;
			background-image: linear-gradient(#2da1f8, #1370b5);
			border: 1px solid #125f99;
			border-radius: 4px;
			outline: none;
			color: $white;
			padding: 13px 104px;
			cursor: pointer;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 26px;
		}

		> p {
			margin-bottom: 52px;
		}
	}
}
