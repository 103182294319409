@import './colors.scss';

.navbar-fixed nav .nav-wrapper.darker-bg {
	background-color: $data-bgc;
	border-bottom: 1px solid $dark-bg;

	.logo-wrap span {
		font-size: 30px;
	}

	ul.list-wrap {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 100%;

		li a {
			display: flex;
			align-items: center;
			padding: 0 20px;
			margin: 0;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			font-family: adobe-clean, sans-serif;

			&:hover {
				text-decoration: none;
			}
		}

		li {
			.link-login {
				border: 1px solid #2b9ef5;
				padding: 7px 23px !important;
				margin-right: 10px;

				&:focus {
					box-shadow: 0 0 0 0;
				}
			}

			.link-register {
				background-image: linear-gradient(#2da1f8, #1370b5);
				border: 1px solid #125f99;
				border-radius: 4px;
				outline: none;
				color: $white;
				padding: 7px 23px;
			}
		}

		.eye i {
			margin-left: 5px;
		}

		.dropdown button#dropdown-basic-button {
			background-color: $data-bgc;
			border-color: $data-bgc;

			&:focus {
				box-shadow: 0 0 0 0;
			}
		}
	}
}

.nav-wrap {
	background-color: $data-bgc;
}

// After Material
.navbar-fixed {
	position: fixed;
	height: 64px;
	z-index: 999;

	nav {
		position: fixed;
		left: 0;
		height: 64px;
		line-height: 64px;
		width: 100%;
		color: $white;
		z-index: 999;
		background-color: $data-bgc;

		.nav-wrapper {
			position: relative;
			height: 100%;

			.bet-nav-wrap {
				display: flex;
			}
		}

		.logofont {
			padding-left: 0;
		}

		.logo-wrap span {
			font-size: 30px;

			a {
				color: $white;
				outline: none;

				&:hover {
					text-decoration: none;
				}
			}
		}

		ul.list-wrap {
			display: flex;
			justify-content: center;
			list-style-type: none;
			padding-left: 0;
			margin: 0;

			li {
				transition: background-color 0.3s;
				padding: 0;

				a {
					color: $white;
					outline: none;

					&:hover {
						background-color: rgba(0, 0, 0, 0.1);
					}

					img.nav-eye {
						width: 16px;
						margin-left: 6px;
					}
				}
			}

			.dropdown.btn-group:focus {
				outline: none;
			}

			.btn-success {
				color: $white;
				background-color: $data-bgc;
				border-color: $data-bgc;

				&:hover,
				&:focus,
				&:active {
					background-color: $data-bgc;
					border-color: $data-bgc;
					box-shadow: 0 0 0 0;
				}
			}

			.btn-success:not(:disabled):not(.disabled):active:focus {
				box-shadow: 0 0 0 0;
			}

			.dropdown-menu.show {
				background-color: $data-bgc;

				a {
					font-size: 16px !important;

					&:hover {
						outline: none;
					}

					&:active,
					&:focus {
						outline: none;
						background-color: $landing-text;
					}
				}
			}
		}

		.nav-row {
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		.max-height {
			height: 100%;
		}
	}
}

.active-link {
	background-color: #1b2431;
}
