@import './colors';

.wallet-wrapper {
	padding-top: 64px;
	background-color: $dark-bg;
	min-height: 100vh;
	color: $white;

	.wallet-row {
		justify-content: space-between;
		align-items: center;
		padding-top: 120px;

		.deposit-wrap,
		.withdraw-wrap {
			max-width: 480px;
			min-height: 240px;

			.col-title {
				font-size: 20px;
				padding-left: 30px;
				margin-bottom: 30px;
			}
		}

		.inner-wrap {
			background-color: #273142;
			color: $white;
			padding: 30px;
			height: 240px;
			position: relative;

			input {
				width: 100%;
				padding: 7px 14px;
				margin-bottom: 28px;
				font-size: 14px;
				border: 1px solid #707070;
			}

			.betup,
			.deposit-btn {
				padding: 12px 86px;
				font-size: 14px;
				border-radius: 4px;
				border: 0;
				background-image: linear-gradient(#2da1f8, #1370b5);
				color: $white;
			}

			.betcoin-address {
				margin-bottom: 35px;
			}

			.myWallet {
				margin-bottom: 52px;
				word-wrap: break-word;
			}

			.deposit-btns-wrap {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				flex-wrap: wrap;

				.cory-msg {
					position: absolute;
					top: -26px;
					left: 50%;
					transform: translate(-50%, 0);
					color: #2ca0f7;
				}
			}

			.qrdiv {
				position: absolute;
				background-color: #fff;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.qrcode {
				z-index: 2;
				color: #2694e6;
				cursor: pointer;
			}
		}
	}
}
