$azure-blue: #37EFBA;
$dark-bg: #1B2431;
$darker-bg: #27272F !important;
$lighter-gray-bg: #3B3B46;
$white: #fff;
$gray-font: #7585A1;
$landing-text: #1B2431;
$data-bgc: #273142;
$green-arr: #66B92E;
$red-arr: #D65B4A;
$btn-blue: #2B9EF5;