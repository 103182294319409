@media (max-width: 480px) {
	.register-row .register-wrap p.have-acc {
		font-size: 15px;
	}

	input#inputCenteredText {
		max-width: 62px !important;
	}

	.not-logged {
		ul.login-btns-wrap {
			position: absolute;
			right: 0 !important;
			left: 0;
			padding: 10px !important;
			// top: 0;
			top: -62px;
			width: 100%;
		}
	}

	.deposit-btn,
	.betup {
		padding: 12px 32px !important;
	}

	.welcome-pop {
		max-height: 600px !important;
		text-align: center;
	}
}

@media (max-width: 767px) {
	.dashboard-data-col {
		margin-bottom: 30px;
	}

	.dashboard-sidebar,
	.chart-wrapper {
		margin-bottom: 14px;
	}

	.popup_inner {
		position: absolute;
		width: 300px;
		box-sizing: border-box;
	}

	.current-bet-wrap .current-bets-list li,
	.current-bet-wrap .all-bets-list li {
		padding: 8px 0px;
	}

	.sidebar {
		margin-top: 0;
		padding-left: 7px;
	}

	.space-bot {
		margin-bottom: 0;
	}

	.nav-row {
		position: relative;

		.logo-wrap {
			position: absolute;
			left: 30px;
			z-index: 2;
		}

		.nav-wrapz {
			position: absolute;
			top: 64px;
			background-color: #273142;
			width: 100%;

			ul.list-wrap {
				display: -webkit-flex;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				height: auto;
				flex-direction: column;
				background-color: #273142;
				height: auto !important;

				li {
					// width: 100%;
					text-align: center;

					a {
						justify-content: center;
					}
				}
			}
		}

		.burger-menu {
			position: absolute;
			right: 21px;
			cursor: pointer;
			width: 32px;
		}
	}

	.max-height {
		height: auto !important;
	}

	.nav-wrapz ul.login-btns-wrap {
		justify-content: flex-end !important;
		padding: 20px 10px;
	}

	.nav-wrapz ul.logged-btns-wrap {
		flex-direction: column;
		border-bottom: 1px solid $dark-bg;
	}

	.register-row {
		.popup_inner {
			padding: 10px;

			* {
				margin-bottom: 20px;
				padding: 0 !important;
				text-align: center;
			}
		}
	}

	ul.login-btns-wrap li .link-register {
		padding: 7px 23px !important;
	}

	ul.login-btns-wrap {
		flex-direction: row !important;
	}

	.not-logged .burger-menu {
		display: none;
	}

	.deposit-wrap,
	.withdraw-wrap {
		margin: 0 auto 30px;

		.inner-wrap {
			height: auto !important;
		}
	}

	.welcome-pop {
		text-align: center;
	}

	.landing-text {
		padding: 0 10px;
	}

	.landing-wrap {
		padding: 150px 0 50px !important;
	}

	.centeredEl,
	.register-wrap {
		// position: absolute;
		// top: 0;
		padding-top: 100px;
		padding-bottom: 50px;
		max-width: 445px;
	}

	.centeredEl {
		padding-left: 14px;
		padding-right: 14px;
	}

	.register-page .link-register {
		display: none !important;
	}

	.login-page .link-login {
		display: none !important;
	}

	.logged-registered .logged-btns-wrap li {
		width: 100%;
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.not-logged {
		ul.login-btns-wrap {
			position: absolute;
			right: 27px !important;
			padding: 10px !important;
			top: -62px;
			width: 50%;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.current-bet-wrap {
		height: 286px !important;
	}

	.burger-menu {
		display: none;
	}

	ul.list-wrap li a {
		padding: 7px 23pxpx !important;
	}

	.nav-wrapz ul.logged-btns-wrap {
		flex-direction: row !important;
	}

	.deposit-wrap,
	.withdraw-wrap {
		width: 49%;

		.inner-wrap {
			height: 300px !important;

			input[type='number'] {
				margin-bottom: 57px;
			}
		}
	}

	.dashboard-data-col {
		margin-bottom: 14px;
	}
}

@media (min-width: 1200px) and (max-width: 1249px) {
	.sidebar .current-bet-wrap {
		height: 280px !important;
	}
}

@media (min-width: 992px) and (max-width: 1249px) {
	.current-bet-wrap {
		height: 286px;
	}

	.current-bet-wrap button.current-bets-btn,
	.current-bet-wrap button.all-bets-btn {
		padding: 10px;
	}

	.burger-menu {
		display: none;
	}

	.nav-wrapz ul.logged-btns-wrap {
		flex-direction: row !important;
	}

	.inner-wrap {
		input[type='number'] {
			margin-bottom: 28px !important;
		}
	}

	.dashboard-sidebar {
		margin-bottom: 14px;
	}

	ul.all-bets-list li span {
		width: 20% !important;
	}
}

@media (min-width: 1250px) {
	.current-bet-wrap {
		height: 286px !important;
	}

	.burger-menu {
		display: none;
	}

	.nav-wrapz ul.logged-btns-wrap {
		flex-direction: row !important;
	}

	.inner-wrap {
		input[type='number'] {
			margin-bottom: 28px !important;
		}
	}
}
