.popup .popup_inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		background-image: linear-gradient(#2DA1F8, #1370B5);
		border: 1px solid #125F99;
		border-radius: 4px;
		outline: none;
		color: #fff;
		padding: 13px 108px;
		cursor: pointer;
		font-size: 14px;
		text-transform: uppercase;
	}

	p.ethNumber {
		font-size: 50px;
	}
}