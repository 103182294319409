.register-wrap {
	max-width: 445px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	.login-form {
		text-align: center;

		.inputs-wrap {
			position: relative;
			line-height: 14px;
			padding: 0;
			color: #7d838e;
			padding-bottom: 20px;
			margin-bottom: 20px !important;

			input {
				border: 1px solid #273142 !important;
				border-radius: 4px !important;
				width: 100%;
				height: 3rem;
				position: relative;
				padding-left: 3%;
			}

			label {
				position: absolute;
				top: 27%;
				left: 3%;
				transition: 0.5s;
				cursor: auto;
			}

			input:focus ~ label,
			input:invalid ~ label {
				top: -8px;
				font-size: 14px;
				color: #007bff;
				background-color: $white;
				padding: 0 5px;
			}

			.red-text {
				color: #007bff;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.text-up {
				top: -8px;
				font-size: 14px;
				color: #007bff;
				background-color: $white;
				padding: 0 5px;
			}

			img.eye-img {
				width: 20px;
				position: absolute;
				right: 5%;
				top: 22%;
				cursor: pointer;
			}
		}

		.inputs-wrap2 {
			position: relative;
			line-height: 14px;
			padding: 0 0 20px;
			color: #7d838e;
			margin-bottom: 20px;

			input {
				border: 1px solid #273142 !important;
				border-radius: 4px !important;
				width: 100%;
				height: 3rem;
				position: relative;
				padding-left: 3%;
			}

			label {
				position: absolute;
				top: 25%;
				left: 3%;
				transition: 0.5s;
				cursor: auto;
			}

			input:focus ~ label {
				top: -8px;
				color: #007bff;
				font-size: 14px;
				background-color: $white;
				padding: 0 5px;
			}

			span.minimum-characters {
				position: absolute;
				left: 0;
				color: #007bff !important;
				opacity: 0;
				transition: 0.5s ease-in;
			}

			input:focus ~ .minimum-characters {
				opacity: 1;
				color: #7d838e;
			}

			.red-text {
				color: #007bff;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.no-of-chars {
				bottom: -19px !important;
			}

			.text-up {
				top: -8px !important;
				font-size: 14px;
				color: #007bff;
				background-color: $white;
				padding: 0 5px;
			}

			img.eye-img {
				width: 20px;
				position: absolute;
				right: 5%;
				top: 22%;
				cursor: pointer;
			}
		}

		.loginbtn-wrap {
			display: flex;
			flex-direction: column;

			button {
				align-self: center;
				background-image: linear-gradient(#2da1f8, #1370b5);
				border: 1px solid #125f99;
				border-radius: 4px;
				outline: none;
				color: $white;
				padding: 13px 104px;
				margin-bottom: 26px;
				cursor: pointer;
				font-size: 14px;
				text-transform: uppercase;
			}

			p {
				margin-bottom: 8px;
			}
		}

		.have-acc {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 26px;

			input {
				margin-right: 5px;
			}
		}
	}

	.bottom-space {
		display: flex;
		margin-bottom: 40px;

		i.material-icons.left {
			margin-right: 10px;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover {
			text-decoration: none;
		}
	}
}

.register-row {
	min-height: 100vh;
	max-width: 445px;
	margin: 0 auto !important;
	display: flex;
	justify-content: center;
	align-items: center;

	.popup {
		.popup_inner {
			width: 310px;
			height: 310px;
			padding: 20px;
			align-items: flex-start !important;
			justify-content: flex-start !important;
		}

		p.popup-title {
			font-size: 20px;
		}

		.popup-reg {
			font-size: 16px;
		}

		p.popup-text {
			font-size: 14px;
		}
	}
}

.center-text {
	text-align: center;

	a {
		margin-bottom: 8px !important;
	}

	p {
		margin-bottom: 26px;
	}
}

.congratz {
	max-width: 310px;
	padding: 20px;
	justify-content: flex-start !important;
	align-items: flex-start !important;
	box-sizing: content-box;

	p.popup-title {
		font-size: 20px;
		margin-bottom: 15px;
	}

	p.popup-reg {
		margin-bottom: 20px;
	}

	p.popup-text {
		margin-bottom: 60px;
	}

	a {
		align-self: flex-end;
		padding: 5px 18px !important;
		width: 60px;
		background-image: linear-gradient(#2da1f8, #1370b5);
		color: #fff;
		border-radius: 4px;
		text-align: center;
		font-size: 14px;

		&:hover {
			text-decoration: none;
		}
	}
}
