@import './colors.scss';

.dark-bg {
	background-color: $dark-bg;
}

.my-app {
	min-height: 100vh;
	padding-top: 64px;

	.popup_inner {
		min-height: 400px;
		max-width: 800px;
	}
}

.dashboard-data-col {
	padding: 0 7px;
	border-radius: 4px;
	display: flex;

	.inner-data-wrap {
		padding: 24px;
		background-color: $data-bgc;
		border-radius: 4px;
		width: 100%;

		h4 {
			font-size: 14px;
			color: $white;
		}

		.inner-data-title {
			font-size: 14px;
			color: white;
			margin-bottom: 8px;
		}

		div {
			color: $gray-font;
			font-size: 11px;
			margin-bottom: 5px;
		}

		.data-val-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-transform: uppercase;

			p {
				margin-bottom: 5px;
			}

			div {
				padding: 0px;
			}

			.data-white-text {
				font-size: 15px;
				color: $white;
			}
		}

		.mar-bot {
			margin-bottom: 40px;
		}

		.data-val-wrap-bot {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 0 30px;
			font-family: 'Open Sans', sans-serif;

			.data-text-blue {
				font-size: 21px;
				color: #2da1f8;
			}

			.data-red-text {
				font-size: 21px;
				color: #d65b4a;
			}

			.data-gray-text {
				font-size: 21px;
				color: #7585a1;
			}

			.data-green-text {
				font-size: 21px;
				color: #61af2f;
				margin-bottom: 5px;
			}

			> div {
				padding-left: 0px;
			}

			.big-text {
				text-transform: uppercase;
				font-size: 10px;
			}
		}

		.remaining-time {
			text-align: center;
		}

		.countdown-wrap {
			display: flex;
			padding: 0 !important;
			align-items: center;
			justify-content: center;
			position: relative;

			.react-countdown-clock {
				padding: 0 !important;
			}

			.remaining-time {
				position: absolute;
				top: 61px;
				text-transform: uppercase;
				font-size: 10px;

				.over-circle {
					height: 78px;
					width: 78px;
					border-radius: 50%;
					position: absolute;
					bottom: -33px;
					left: -39px;
					background-color: #273142;
					display: -webkit-flex;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.bets-times {
						color: $white;
						font-size: 20px;
						font-family: 'Open Sans', sans-serif;
						margin-bottom: 0;
						line-height: 20px;
					}

					.bet-text {
						font-size: 10px;
						font-family: 'Open Sans', sans-serif;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.betting-wrap {
	padding-top: 15px;
}

.sidebar {
	margin-top: 14px;
	padding-left: 22px;

	.bet-history {
		border-radius: 4px;
	}
}

.space-bot {
	margin-bottom: 14px;
}

.dashboard-sidebar {
	color: $gray-font;
	padding: 16px 24px;
	border-radius: 4px;

	.betting-title {
		font-size: 14px;
		color: $white;
		margin-bottom: 10px;
	}

	.white-total {
		color: #fff;
		font-size: 18px;
	}

	.popup {
		z-index: 999;
	}
}

.lighter-gray-bg {
	background-color: $data-bgc;

	.dashboard-sidebar-title-wrap h4 {
		font-size: 14px;
		color: $white;
		text-align: center;
	}

	.cur-balance-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 11px;
		padding: 14px 0;
		border-bottom: 1px solid #3e4a5f;
		border-top: 1px solid #3e4a5f;
	}

	.sidebar-total {
		font-size: 11px;
		text-transform: uppercase;
	}

	.total-eth-wrap {
		padding: 14px 0px;

		p {
			font-size: 11px;
			color: #7585a1;
			margin-bottom: 6px;
		}
	}

	.sidebar-estimated {
		font-size: 11px;
		text-transform: uppercase;
		margin-bottom: 18px;
		background-color: #313d4f;
		border-radius: 4px;
		padding: 10px;
		text-align: center;

		.estimated-up-down {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			padding: 10px 10px 0;

			.estimated-up {
				margin-bottom: 0;
				color: $green-arr;
				font-size: 16px;
				font-family: 'Open Sans', sans-serif;
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: center;

				p {
					margin: 0;
				}

				img {
					margin-right: 20px;
				}
			}

			.estimated-down {
				margin-bottom: 0;
				color: $red-arr;
				font-size: 16px;
				font-family: 'Open Sans', sans-serif;
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: center;

				p {
					margin: 0;
				}

				img {
					margin-right: 20px;
				}
			}
		}
	}

	input#inputCenteredText {
		background-color: $white;
		color: #000 !important;
		text-align: center;
		padding: 0 9px;
		box-sizing: content-box;
		border: 0;
		border-radius: 2px;
		max-width: 86px;
		height: 26px;

		&:focus::-webkit-input-placeholder {
			color: transparent;
		}
	}

	.total-eth-wrap {
		display: flex;
		justify-content: space-between;
	}

	.side-btn-wrap {
		display: flex;
		justify-content: space-between;
		margin-bottom: 18px;

		button.betup,
		button.betdown {
			background-color: #273142;
			color: $white;
			border: none;
			padding: 10px;
			border-radius: 4px;
			cursor: pointer;
			width: 47%;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			text-transform: uppercase;
			border: 1px solid rgba(117, 133, 161, 0.301);

			&:focus {
				outline: none;
			}

			&:hover {
				background-color: #3e4a5f;
			}

			&:active {
				background-image: linear-gradient(#1370b5, #2da1f8);
			}

			.arrow-up {
				color: $green-arr;
				font-size: 22px;
				margin-right: 5px;
			}

			.arrow-down {
				color: $red-arr;
				font-size: 22px;
				margin-right: 5px;
			}
		}

		.up-pressed,
		.down-pressed {
			background-image: linear-gradient(#2da1f8, #1370b5);
			border: 1px solid rgba(18, 95, 153, 0.315);
		}
	}

	.place-btn-wrap {
		button {
			background-image: linear-gradient(#2da1f8, #1370b5);
			border: 1px solid #125f99;
			border-radius: 4px;
			outline: none;
			color: $white;
			padding: 13px;
			cursor: pointer;
			font-size: 14px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			> div {
				margin-right: 10px;
			}
		}

		.inactive-btn {
			background-image: linear-gradient(#273142, #273142) !important;
			border-color: #3e4a5f !important;
		}

		.accepted-btn {
			background-image: linear-gradient(#276ea7, #1d5b8d) !important;
			border-color: #125f99 !important;
		}
	}
}

.current-bet-wrap {
	width: 100%;
	height: 221px;
	max-height: 100%;
	overflow-y: scroll;

	h4 {
		font-size: 14px;
		color: $white;
	}

	button.current-bets-btn,
	button.all-bets-btn {
		font-size: 14px;
		background-color: #313d4f;
		border-color: transparent;
		color: $white;
		padding: 13px;
		cursor: pointer;
		width: 50%;

		&:focus,
		&.active {
			outline: none;
			background-color: #273142;
		}
	}

	.clicked-tab {
		background-color: #273142 !important;
	}

	.unclicked-tab {
		background-color: #313d4f !important;
	}

	button.current-bets-btn {
		border-right: 1px solid #273142;
	}

	.current-bets-list,
	.all-bets-list {
		margin: 0;
		padding: 2px 24px;
		list-style-type: none;

		li {
			display: flex;
			font-size: 12px;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #1b2431;
			padding: 2px 0px;

			.all-bets-eth-amount {
				color: $white;
			}

			.all-bets-date-time {
				color: #b2b2b2;
				text-align: right !important;
			}

			.current-down,
			.current-up,
			.all-bets-eth-amount,
			.all-bets-date-time {
				width: 28%;
				font-size: 12px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.won-lost {
				width: 10%;
				text-transform: uppercase;
				font-size: 10px;
			}

			.lost-text {
				color: #d65b4a;
			}

			.won-text {
				color: #61af2f;
			}
		}

		.current-down,
		.current-up {
			color: $white;
			text-transform: uppercase;
			display: flex;
			align-items: center;

			img {
				margin-right: 2px;
			}
		}
	}
}

.current-bet-wrap::-webkit-scrollbar-track {
	box-shadow: inset 0 -20px 1px 3px #273142;
	background-color: #273142d7;
}

.current-bet-wrap::-webkit-scrollbar {
	width: 8px;
	background-color: #fff;
}

.current-bet-wrap::-webkit-scrollbar-thumb {
	border-radius: 15px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #fff;
}

.graph-list-wrap {
	display: flex;
	justify-content: flex-end;
	padding-top: 20px;

	select {
		background-color: #1b2431;
		border: 1px solid #7585a1;
		color: #fff;
		padding: 5px 20px;
		font-size: 14px;

		&:focus {
			outline: none;
		}
	}
}

.shadow-wrap {
	position: relative;
	overflow: hidden;

	.shadowEl {
		position: absolute;
		bottom: 0;
		width: calc(100% - 14px);
		height: 24px;
		background-image: linear-gradient(#27314200, #00000024);
	}
}

.welcome-pop {
	padding: 25px;
	justify-content: flex-start !important;
	max-width: 800px;
	height: 100%;
	box-sizing: border-box;

	p.hello-text {
		font-size: 30px;
		text-align: center;
		margin-bottom: 20px;

		span {
			font-weight: bold;
		}
	}

	p.ethNumber {
		margin-bottom: 40px;
	}

	.welcome-info {
		margin-bottom: 35px;
		text-align: center;
	}
}
