.reset {
	margin: 0 !important;
	padding: 0 !important;
}

.container {
	padding-left: 7px !important;
}

.robot {
	font-family: 'Roboto', sans-serif;
}

.sans {
	font-family: 'Open Sans', sans-serif;
}

p {
	margin: 0;
}