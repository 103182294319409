.container.login {
	max-width: 445px;

	.loginbtn-wrap {
		display: flex;
		flex-direction: column;

		button {
			align-self: center;
			background-image: linear-gradient(#2da1f8, #1370b5);
			border: 1px solid #125f99;
			border-radius: 4px;
			outline: none;
			color: $white;
			padding: 13px 104px;
			cursor: pointer;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	.bottom-space {
		margin-bottom: 40px !important;
		display: flex;

		i.material-icons.left {
			margin-right: 10px;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover {
			text-decoration: none;
		}
	}

	form.login-form {
		text-align: center;
		color: #7d838e;

		.inputs-wrap {
			position: relative;
			line-height: 14px;
			padding-bottom: 20px;
			margin-bottom: 20px !important;

			.red-text {
				color: #007bff;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			input:focus ~ label,
			input:invalid ~ label {
				top: -8px;
				font-size: 14px;
				color: #007bff;
				background-color: $white;
				padding: 0 5px;
			}

			.text-up {
				top: -8px;
				font-size: 14px;
				color: #007bff;
				background-color: $white;
				padding: 0 5px;
			}

			input#password:invalid ~ label {
				top: -8px;
				font-size: 14px;
				color: #007bff;
				background-color: $white;
				padding: 0 5px;
			}

			img.eye-img {
				width: 20px;
				position: absolute;
				right: 5%;
				top: 22%;
				cursor: pointer;
			}
		}

		input {
			border: 1px solid #273142 !important;
			border-radius: 4px !important;
			width: 100%;
			height: 3rem;
			position: relative;
			padding-left: 3%;
		}

		label {
			position: absolute;
			top: 27%;
			left: 3%;
			transition: 0.5s;
			cursor: auto;
		}

		input:focus ~ label,
		input:invalid ~ label {
			top: -8px;
			font-size: 14px;
			color: #007bff;
			background-color: $white;
			padding: 0 5px;
		}

		.input-field.col.s12 {
			padding-left: 0px;
			padding-right: 0px;
		}

		> p {
			margin-bottom: 26px;
			color: #273142;
		}

		.loginbtn-wrap {
			button {
				margin-bottom: 26px;
			}

			p {
				margin-bottom: 26px;
				color: #273142;

				a {
					margin-left: 5px;
				}
			}
		}
	}
}

.have-acc a {
	margin-left: 5px;
}

.login-row {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
